export default {
    /*  需要添加
    mounted() {
            this.qywxWebLogHandler();
    },
     */
    methods: {
        qywxWebLogHandler() {
            const vm = this;
            if (this.$organization.wx_redirect && this.$route.query.code && this.$route.query.state) {// read user info
                this.$api('Wechat.QYWeb' + '?code=' + this.$route.query.code + '&state=' + this.$route.query.state, null).then(res => {
                    if (res.data.success) {
                        vm.setUserInfo(res.data.data);
                        vm.$router.replace("/");
                    } else {
                        let msg = '错误的企业微信返回信息,请用其他方式登录!';
                        if (res.data.text && res.data.text != 'INVALID') msg = res.data.text;
                        vm.$messageBox.error(msg);
                        vm.$router.replace("/sign-in");
                        vm.ready = true;
                    }
                });
            } else if (this.$organization.oauth) {
                if (this.$route.query.code && this.$route.query.state && this.$route.query.state === 'zoom') {
                    this.$api('OAuth', {code: this.$route.query.code}).then(res => {
                        if (res.data.success) {
                            if (res.data.data.error) {
                                this.show_login_error = true;
                                this.error_message = res.data.data.message;
                            } else {
                                vm.setUserInfo(res.data.data);
                                vm.$router.replace("/");
                            }
                        } else {
                            this.$messageBox.error(res.data.text).then(() => {
                                this.jumpToSignIn()
                            }).catch(() => {
                                this.jumpToSignIn();
                            })
                        }
                    });
                } else {
                    this.page_ready = true;
                }
            } else {
                this.page_ready = true;
            }
        },
        qywxHandler() {
            const vm = this;
            if (this.$isWechat()) {
                if (this.$organization.wechat) { // 是否是微信客户端，并且有企业微信登录设置
                    this.page_ready = false;
                    // 第一步判断是否有code和state
                    if (this.$route.query.code && this.$route.query.state) {
                        this.$api('User.Wechat', {code: this.$route.query.code, state: this.$route.query.state, t:this.$route.query._t}, 1)
                            .then(res => {
                                const ret = res.data.data;
                                if (ret.user) { // 已经成功了
                                    vm.setUserInfo(ret.user);
                                    let url = this.$route.query._t?decodeURIComponent(this.$route.query._t):'/portal';
                                    vm.$router.replace(url);
                                } else {// 如果都不行，则进行网络登录
                                    vm.$messageBox.error('微信登录失败，请正常登录!');
                                    this.page_ready = true;
                                }
                            })
                            .catch(() => {
                                this.page_ready = true;
                            });
                    } else {
                        // 访问vuex 中的 preset.app 是否已经有了 wx
                        if (this.$store.state.preset.app && this.$store.state.preset.app.wechat) {
                            const url = this.$store.state.preset.app.wechat;
                            window.location.href = this.makeReturnUri(url);
                        } else {
                            this.$apiJson('storage/preset.json', {_t: Math.random()}).then(res => {
                                const url = res.data.wechat;
                                if (url) {
                                    window.location.href = this.makeReturnUri(url);
                                } else this.page_ready = true;
                            }).catch(() => {
                                this.page_ready = true;
                            });
                        }
                    }
                } else {
                    this.page_ready = true;
                }
            }
        },
        jumpToWechatCorp() {
            window.location.href = this.$organization.wx_redirect;
        },
        makeReturnUri(urlString) {
            const parsedUrl = new URL(urlString);
            const origin = parsedUrl.searchParams.get('redirect_uri');
            parsedUrl.searchParams.set('redirect_uri', this.$route.query._t ? origin + '?_t=' + this.$route.query._t : origin);
            return parsedUrl.toString();
        }
    }
}
