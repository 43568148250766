<template>
    <div class="" v-if="preset.app">
        <div v-if="preset.app && preset.app.app_footer" v-html="preset.app.app_footer">
        </div>
        <div v-else>
            Copyright © 2021. {{ $organization.org_name }}.
        </div>
    </div>
</template>

<script>
import commonMixin from "@/common/mixins/common-mixin";

export default {
    name: "page-footer",
    mixins: [commonMixin],
}
</script>

<style lang="scss">
.page-container {
    .contact {
        color: #eee;
        position: relative;
        text-align: center;
        padding: 15px 0 20px 4px;
        line-height: 26px;
        font-size: 14px;

        a {
            color: #b8f7ff;
        }
    }
}
</style>
