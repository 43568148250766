<template>
    <div class="mobile-dark" v-show="page_ready">
        <div class="header-wrap" style="flex: 0 0 220px;height: 220px;" :style="{backgroundImage:'url('+bg_image+')'}">
            <div class="header-logo flex-in-middle" style="height: 144px;">
                <img :src="logo" alt="" style="max-width: 100%">
            </div>
        </div>
        <div class="login-wrap">
            <section class="login-box">
                <div class="selection">
                    <div class="login-container d-flex flex-in-middle position-relative">
                        <div class="position-relative" style="padding: 0 10px;" >
                            <div class="title active">{{
                                    $t('TITLE.NETID')
                                }}
                            </div>
                            <div class="title-indicator" style="width: 100%;bottom: 10px;"></div>
                        </div>
                    </div>
                </div>
                <div class="body">
                    <div class="login-container h-100 position-relative">
                        <div class="login-tab" :class="{active:tab==='sso'}">
                            <div class="flex-in-middle flex-column h-100">
                                <div class="w-100 mt-5">
                                    <nut-button block shape="circle" type="highlight" style="font-size: 20px;"
                                                @click="handleSSO">
                                        <i class="el-icon-star-off"></i>
                                        {{ $organization.sso_name ? $organization.sso_name : "SSO" }}
                                    </nut-button>
                                </div>
                                <div class="w-100 text-muted mt-5 pt-5" style="font-size: 14px;">
                                    {{ $t("MESSAGE.WELCOME") }}
                                </div>
                            </div>
                        </div>
                        <div class="login-tab" :class="{active:tab==='local'}">
                            <div class="h-100 flex-in-middle">
                                <div class="w-100 mb-5">
                                    <div>
                                        <nut-textinput
                                            v-model="login.id"
                                            placeholder="Your ID"
                                            clearBtn
                                        >
                                        </nut-textinput>
                                    </div>
                                    <div style="margin-top: 34px;">
                                        <nut-textinput
                                            v-model="login.password"
                                            placeholder="Password"
                                            clearBtn
                                            type="password"
                                        >

                                        </nut-textinput>
                                    </div>
                                    <div style="margin-top: 43px;">
                                        <nut-button block shape="circle" type="highlight" @click="submitForm"
                                                    style="height: 45px;font-size: 18px;">
                                            {{ $t("TITLE.SIGN-IN") }}
                                        </nut-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="language">
                            <div class="mr-2 locale" @click="setLocale">
                                {{ english ? "LANGUAGE: English" : "LANGUAGE: 中文" }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="foot">
                    <page-footer></page-footer>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import "@/plugins/nutui"
import SignIn from "@/views/mixins/signin-mixin"
import PageFooter from "@/components/page-footer";
import qywxCommon  from "../../common/mixins/qywxCommon";
import VueCookie from "vuecookie";

export default {
    components: {PageFooter},
    mixins: [SignIn,qywxCommon],
    mounted() {
        if (!this.jumping) this.qywxHandler();
    },
    created() {
        const vm = this;
        if (this.$route.query.schoolid === '74942' && !this.jumping ) { //大讲堂跳转
            this.jumping = true;
            this.loading = true;
            this.$api('User.SufeDjtLogin',this.$route.query,1).then((res)=>{
                VueCookie.set('SUFEDJTLOGIN',1,{expires:'1D'});
                const ret = res.data.data;
                if (ret.user) { // 已经登录了，则进入
                    //vm.$messageBox.error('Has User');
                    vm.setUserInfo(ret.user);
                    vm.$router.replace("/");
                } else {// 如果都不行，则进行网络登录
                    this.loading = false;
                    vm.$messageBox.error(ret.message);
                    vm.$router.replace("/");
                }
            });
        }
    },
}
</script>

<style lang="scss">

.mobile-dark {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;

    .header-wrap {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
    }

    .login-wrap {
        margin-top: -76px;
        display: flex;
        z-index: 10;
        flex: 1;
        background-color: RGBA(0, 0, 0, 0.7);
        border-radius: 20px 20px 0 0;

        .login-box {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;

            .login-container {
                width: 300px;
                margin: auto;
            }

            .selection {
                flex: 0 0 74px;
                width: 100%;

                .title {
                    flex: 0 0 120px;
                    color: white;
                    font-size: 20px;
                    line-height: 74px;
                    text-align: center;
                    transition: color ease-in 200ms;

                    &.active {
                        color: $--public-highlight;
                    }

                }

                .title-indicator {
                    position: absolute;
                    width: 120px;
                    height: 3px;
                    background-color: $--public-highlight;
                    left: 0;
                    bottom: 20px;
                    transition: left ease-in 200ms;

                    &.right {
                        left: 180px;
                    }
                }

            }

            .body {
                flex: 1;
                background-color: #F1F1F1;
                border-radius: 0 0 20px 20px;
                box-shadow: 0 2px 6px #000000;
                min-height: 400px;

                .language {
                    position: absolute;
                    bottom: 20px;
                    right: 0;
                    width: 100%;
                    text-align: center;
                    color: #999999;
                    cursor: pointer;
                    transition: color ease-in 150ms;
                    font-size: 14px;
                    font-weight: bold;
                }

                .login-tab {
                    position: absolute;
                    top: 20px;
                    width: 100%;
                    bottom: 40px;
                    display: none;

                    &.active {
                        display: block;
                        animation: pane-move 1000ms;
                    }

                    @keyframes pane-move {
                        0% {
                            opacity: 0;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                }
            }

            .foot {
                flex: 0 0 40px;
                text-align: center;
                color: white;
                font-size: 14px;
                line-height: 35px;
            }

        }
    }
}

</style>
